<template>
    <div class="v-nav-bar">
        <ul class="menu-list">
            <router-link :to="{ name: 'Main', hash: '#main-page'}" style="text-decoration: none;"><li class="menu-item">Главная</li></router-link>
            <router-link :to="{ name: 'Common', hash: '#common-page'}" style="text-decoration: none;"><li class="menu-item">Общественное отделение</li></router-link>
            <router-link :to="{ name: 'Family', hash: '#numbers'}" style="text-decoration: none;"><li class="menu-item">Номера</li></router-link>
            <router-link :to="{ name: 'Price', hash: '#prices'}" style="text-decoration: none;"><li class="menu-item">Цены</li></router-link>
            <router-link :to="{ name: 'Discont', hash: '#disconts'}" style="text-decoration: none;"><li class="menu-item">Акции</li></router-link>
            <router-link :to="{ name: 'Contacts', hash: '#contacts'}" style="text-decoration: none;"><li class="menu-item">Контакты</li></router-link>
            <router-link :to="{ name: 'Rules', hash: '#rules'}" style="text-decoration: none;"><li class="menu-item">Правила посещения</li></router-link>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'v-nav-bar',
    }
</script>

<style>
.v-nav-bar {
    margin: none;
    display: block;
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: #121820;
}

.menu-list {
    text-align: center;
    list-style: none;
}

.menu-item {
    display: inline-block;
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1.2em;
    color: white;
    cursor: pointer;
}

.menu-item:hover {
    color: #D2B48C;
}

@media screen and (max-width: 700px) {
    .menu-item {
        margin-left: 0px;
        display: block;
        margin-top: 10px;
        font-size: 20px;
    }
}

@media screen and (min-width: 701px) and  (max-width: 1100px){
    .menu-item {
        font-size: 1em;
    }
}
</style>