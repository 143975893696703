<template>
<div class="v-main-page" id="common-page">
  <Gallery />
  <div class="text-container">
      <img src="@/images/banya/banyaLogo.png" width="100px"/>
      <div class="zagolovok" id="zagolovok">Общественное отделение</div>
  </div>
  <div class="help-background">
      <div class="help" style="padding-top: 10px; font-style: normal;">
        <div class="rules-position">
            <div class="rules dop-price-page" style="font-size: 20px; font-weight: bold;">Пятница: </div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Время работы: с 15.00 до 21.00 (время сеанса 2 часа 30 минут)</div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Стоимость билета: взрослые 700 рублей, дети до 12 лет 350 рублей, дети до 7 лет бесплатно.</div>
            <div class="rules dop-price-page" style="font-size: 20px; font-weight: bold;">Суббота, воскресенье: </div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Время работы: с 11.00 до 21.00 (время сеанса 2 часа 30 минут)</div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Стоимость билета: взрослые 850 рублей, дети до 12 лет 400 рублей, дети до 7 лет бесплатно. </div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Стоимость билета с каминным залом (2 часа 30 минут) 1200 рублей, дети до 12 лет 600 рублей, дети до 7 лет бесплатно.</div>
            <div class="rules dop-price-page" style="text-indent: 3em; padding-top: 20px;">Дополнительное время нахождения в общем отделении оплачивается в соотношении 1 минута - 7 рублей (420 рублей час). </div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Безлимитное пребывание в общем отделении 1800 рублей.</div>
            <div class="rules dop-price-page" style="text-indent: 3em;">Аренда во время сеанса общего отделения «Каминного зала» 3500 рублей/час, вместимость 8 человек.</div>
        </div>
      </div>
  </div>
  <!--  -->
  <!-- <div class="alert">
    <div class="alert-header">Внимание!</div>
    <div class="alert-text"><b>Режим работы общих отделений с 13 мая 2024г.</b></div>
    <div class="alert-text">Пятница - выходной</div>
    <div class="alert-text">Суббота -  с 11.00 до 21.00 </div>
    <div class="alert-text">Воскресенье - с 11.00 до 21.00</div>
    <div class="alert-text"><b>Режим работы общих отделений с 1-27 июня 2024г.</b></div>
    <div class="alert-text">Суббота -  мужской день с 11.00 до 21.00</div>
    <div class="alert-text">Воскресенье – женский день с 11.00 до 21.00</div>
    <br>
    <br>
    <div class="alert-text"><b>С 27 июня общее отделение закрывается до сентября.</b> </div>
    <div class="alert-text">Индивидуальные номера работают все лето, даже при отключении горячего водоснабжения.</div>
  </div> -->
  <!--  -->
</div>
</template>

<script>
import Gallery from '@/components/v-common-gallery'

export default {
  name: 'common-page',
  components: {
    Gallery,
  }
}
</script>

<style>

.alert {
  box-sizing: border-box;
  padding-right: 10px;
  width: 100%;
  padding-bottom: 20px;
  border-top: 3px solid #121820;
}

.alert-header {
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.alert-text {
  margin: auto;
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
}

</style>
