<template>
<div class="v-rules-page" id="rules">
  <div class="rules" style="padding-top: 20px;">Банный комплекс «Эрчим» - это общественное место отдыха. Гости должны соблюдать общепринятые правила поведения, уважительно относиться друг к другу и не мешать отдыху других посетителей. Взаимоуважение – залог комфорта каждого клиента.</div>
  <div class="rules-position">
    <legend class="rules-zag">Общие положения</legend>
    <div class="rules">1.	Настоящим документом устанавливаются общие правила (далее Правила) на всей территории Банного комплекса «Эрчим» (далее – Комплекс).</div>
    <div class="rules">2.	Настоящие правила распространяются на всех гостей и посетителей Комплекса.</div>
    <div class="rules">3.	Гость Комплекса обязан довести информацию, содержащуюся в настоящих Правилах до сведения всех находящихся с ним. При этом ответственным за соблюдение настоящих Правил является персонально каждый Гость.</div>
    <div class="rules">4.	Оплата услуг и посещение Комплекса является подтверждением того, что Гость ознакомлен с данными Правилами и полностью с ними согласен, несет полную ответственность за их нарушение в соответствии с настоящими Правилами и действующим законодательством РФ.</div>
    <div class="rules">5.	Время работы Комплекса – круглосуточно. Время работы общих отделений согласно графику.</div>
    <div class="rules">6.	Все услуги Комплекса предоставляются после внесения 100% предоплаты.</div>
    <div class="rules">7.	Правила дополняются соответствующими Условиями при посещении отдельных зон отдыха: общих отделений, комплекса отдельных номеров. Данные Условия являются обязательными для исполнения и неотъемлемой частью Правил.</div>
    <div class="rules">8.	Данные Правила находятся в общем доступе на стойке информационных сойках в фойе комплекса, общих отделения, а также на сайте.</div>
    <div class="rules">9.	Обслуживание производится на русском языке.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">На территории Комплекса запрещено:</legend>
    <div class="rules">1.	Нахождение несовершеннолетних детей до 18 лет без сопровождения взрослых.</div>
    <div class="rules">2.	Разводить костры и пользоваться открытым огнём вне отведенных для этих целей мест.</div>
    <div class="rules">3.	Нанесение вреда зданиям, постройкам, оборудованию, мебели, зеленым насаждениям Комплекса.</div>
    <div class="rules">4.	Приводить (приносить) собак, кошек и других домашних животных.</div>
    <div class="rules">5.	Выносить из помещений и зданий имущество, принадлежащее Комплексу;</div>
    <div class="rules">6.	Привозить или проносить на территорию Комплекса еду и напитки;</div>
    <div class="rules">7.	Выносить из ресторана посуду;</div>
    <div class="rules">8.	Хранить и использовать пиротехнические изделия;</div>
    <div class="rules">9.	Открывать окна и двери в служебных и технических помещениях, а также щитах электрооборудования;</div>
    <div class="rules">10. Употреблять нецензурные выражения и совершать хулиганские действия;</div>
    <div class="rules">11. Курение вне отведенных для этих целей мест;</div>
    <div class="rules">12. Ношение и хранение оружия, взрывчатых и легковоспламеняющихся, едких, ядовитых и наркотических веществ. Гости, имеющие по роду своей деятельности право на ношение и хранение оружия, обязаны предоставить документы, удостоверяющие данное право, по требованию Администрации Комплекса. (Под оружием следует понимать средства, указанные в законе Российской Федерации "Об оружии");</div>
    <div class="rules">13. Игнорировать требования Администрации Комплекса по соблюдению данных Правил;</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Обязанности гостей Комплекса:</legend>
    <div class="rules">1.	В период пребывания в Комплексе соблюдать морально-этические нормы, воздерживаться в местах массового отдыха Гостей от чрезмерного употребления алкоголя и нецензурных выражений. Уважать право других Гостей на отдых, не оскорблять действиями и словами Гостей Комплекса и обслуживающий персонал;</div>
    <div class="rules">2.	Беречь имущество Комплекса, предоставленное Гостю во временное пользование. В случае нанесения ущерба имуществу, включая ущерб причиненный использованием имущества не по прямому назначению, возмещать убытки в соответствии с рыночной стоимостью утраченного или пришедшего в негодность имущества, не зависимо от вероятности приведения имущества в первоначальный вид;</div>
    <div class="rules">3.	Соблюдать правила пожарной безопасности на всех объектах инфраструктуры Комплекса;</div>
    <div class="rules">4.	Следить за своими личными вещами и имуществом самостоятельно;</div>
    <div class="rules">5.	Нести ответственность за жизнь и здоровье своих несовершеннолетних детей отдыхающих на территории Комплекса;</div>
    <div class="rules">6.	При возникновении чрезвычайной ситуации (пожар, авария, угроза террористического акта) Вам необходимо без паники покинуть Комплекс, максимально быстро (если позволяет ситуация) надеть верхнюю одежду и покинуть территорию и здания, используя основные и запасные (эвакуационные) выходы или лестницы и следуя указаниям сотрудников Комплекса.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Права гостей комплекса:</legend>
    <div class="rules">1.	Пользоваться всеми услугами Комплекса;</div>
    <div class="rules">2.	Получать информацию о часах работы, стоимости, перечне услуг, оказываемых Комплексом, производить предварительное бронирование услуг;</div>
    <div class="rules">3.	Обращаться к Администраторам Комплекса по вопросам качества оказанных услуг;</div>
    <div class="rules">4.	Согласовывать с сотрудниками Комплекса удобное время предоставления дополнительных услуг.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Обязанности Администрации Комплекса</legend>
    <div class="rules">1.	Своевременно и в полном объеме предоставлять Гостю предложенные и оплаченные им услуги;</div>
    <div class="rules">2.	Обеспечить полное соответствие нормам СЭС, другим нормативным актам качество предлагаемых услуг на всех объектах Комплекса;</div>
    <div class="rules">3.	Соблюдать конфиденциальность информации о Гостях Комплекса, в соответствие с законодательством Российской Федерации;</div>
    <div class="rules">4.	Своевременно реагировать на просьбы Гостя в устранении поломок и неисправностей в отдельных номерах и на объектах инфраструктуры Комплекса.</div>
    <div class="rules">5.	Администрация Комплекса не несет ответственности за личные вещи Клиента (часы, драгоценности, деньги, автомобили и т.п.) во время посещения Комплекса.</div>
    <div class="rules">6.	Администрация Комплекса не несёт ответственности за жизнь и здоровье несовершеннолетних детей, оставленных без присмотра их родителей или опекунов.</div>
    <div class="rules">7.	Администрация Комплекса не несет ответственность за состояние здоровья Гостя в случае употребления им различных лекарственных препаратов, напитков и продуктов питания.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Администрация Комплекса имеет право:</legend>
    <div class="rules">1.	Отказать Гостю в посещении Комплекса без объяснения причин;</div>
    <div class="rules">2.	Отказать Гостю в предоставлении дополнительных услуг, либо прервать оказание услуги в случаях нахождения Гостя в состоянии алкогольного, наркотического или токсического опьянения;</div>
    <div class="rules">3.	Отказать Гостю в дальнейшем пребывании в случае грубого нарушения настоящих Правил или проявления со стороны Гостя в отношении отдыхающих и обслуживающего персонала агрессии или действий, угрожающих безопасности здоровья или имущества других лиц, с вызовом, в случае необходимости, компетентных органов. Гость при этом должен покинуть территорию Комплекса незамедлительно;</div>
    <div class="rules">4.	Администрация Комплекса оставляет за собой право отказать в предоставлении услуг посетителям, которые прежде были замечены в неподобающем поведении, порче и/или краже имущества и/или неподчинении законным требованиям администрации, без объяснения причины отказа.</div>
    <div class="rules">5.	При нарушении правил поведения и Правил посещения Комплекса, а также вынужденном удалении Гостя из Комплекса денежное возмещение за неиспользованное время не производится. (ст. 32 закона РФ "О защите прав потребителей" и 782 ГК).</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Дополнения к правилам</legend>
    <div class="rules">- За утерю браслета с посетителей бани взимается штраф в размере 500 рублей</div>
    <div class="rules">- После 23.00 (основное время работы) действует специальный тариф на посещение бань. Дополнительное время начисляется в программе автоматически.</div>
    <div class="rules">- Время на оказание услуг (массаж, скрабирование, парение) не останавливается.</div>
  </div>
  <div class="rules" style="padding-top: 20px; font-weight: bold;">Книга жалоб и предложений находится у дежурного Администратора и выдается по первому требованию Гостя.</div>
  <div class="rules" style="font-weight: bold;">Благодарим Вас за соблюдение настоящих Правил!</div>
  <div class="rules-position">
    <legend class="rules-zag">Условия посещения комплекса отдельных номеров:</legend>
    <div class="rules">Настоящие правила разработаны в соответствии с Федеральным Законом "О защите прав потребителей", "Правил бытового обслуживания населения", другими нормативными актами РФ и обязательны к выполнению всеми Гостями Банного комплекса "Эрчим" (далее по тексту - Комплекс).;</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Правила бронирования и оплаты:</legend>
    <div class="rules">1.	Минимальный сеанс при аренде отдельного номера – 2 часа. Минимальный сеанс при аренде общего отделения – 3 часа</div>
    <div class="rules">2.	Гостям рекомендуется осуществлять предварительную запись для обеспечения желаемого времени сеанса в выбранном номере;</div>
    <div class="rules">3.	При посещении отдельного номера необходимо внести залог в размере 1 000 рублей, за своевременный выход и сохранность имущества, а при аренде общего отделения в размере 5 000 рублей. </div>
    <div class="rules">4.	За 3 (три) дня до времени предварительной записи в периоды повышенного спроса (выходные: пятница-воскресение и праздники) может понадобиться внесение предоплаты или полной оплаты для сохранения брони.</div>
    <div class="rules">5.	При отказе от предварительной записи необходимо сообщить об этом Администратору не позднее, чем за 24 часа до времени начала сеанса, в этом случае денежные средства будут возвращены. В противном случае денежные средства (предоплата) не возвращаются, как фактически понесенные затраты;</div>
    <div class="rules">6.	При посещении комплекса бань по предварительной записи рекомендуется приходить к назначенному времени. Просим относиться с пониманием к тому, что опоздание происходит за счет Гостей и сеанс может быть продлен только на общих основаниях с оплатой дополнительного времени аренды;</div>
    <div class="rules">7.	При опоздании более чем на 30 минут администрация комплекса имеет право распоряжаться забронированной баней по собственному усмотрению. Предоплата в этом случае не возвращается, как фактически понесенные затраты;</div>
    <div class="rules">8.	Время переодевания входит в стоимость оплаченного сеанса;</div>
    <div class="rules">9.	При неполном использовании оплаченного времени – денежное возмещение не производится;</div>
    <div class="rules">10.	При обнаружении каких-либо дефектов до начала сеанса, гость должен незамедлительно сообщить Администратору об этом.</div>
    <div class="rules">11.	Гость может продлить время пребывания в бане, известив об этом Администратора и оплатив последующий сеанс, если время до начала следующего сеанса достаточно для продления.</div>
    <div class="rules">12.	Гость обязан покинуть отдельный номер после окончания оплаченного времени.</div>
    <div class="rules">13.	Отдельный номер считается сданным после осмотра администратором, сдачи всех взятых в аренду вещей и выхода последнего Гостя;</div>
    <div class="rules">14.	Задержка Гостя в комплексе сверх оплаченного времени более 15 минут, оплачивается им в полном размере за 30 минут. Если задержка составит более 30 минут, оплачивается 1 час по прейскуранту.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">Правила пребывания в отдельном номере:</legend>
    <div class="rules">1.	Мы заботимся о здоровье наших Гостей, поэтому настоятельно рекомендуем проконсультироваться с лечащим врачом перед посещением бани.</div>
    <div class="rules">2.	Гости обязаны соблюдать чистоту и порядок в помещениях банного комплекса.</div>
    <div class="rules">3.	Температурный режим в помещениях устанавливается только сотрудниками комплекса, действующая температура в парилке 80-100° С. По желанию Клиента температура может быть отрегулирована.</div>
    <div class="rules">4.	Запрещается посещение Комплекса лицам, находящимся в состоянии алкогольного, наркотического или токсикологического опьянения.</div>
    <div class="rules">5.	При посещении парной, бассейна запрещено: </div>
    <div class="rules">- использование моющих, косметических средств, красок для волос, скраба, мёда и т.п.; </div>
    <div class="rules">- бросать в воду продукты питания, сигареты, бутылки и другие предметы. </div>
    <div class="rules">6.	В случае нарушений правил Гость обязан оплатить дополнительную услугу специальной уборки.</div>
    <div class="rules">7.	Не рекомендуется ходить в зоне банного комплекса босиком во избежание падения на скользком полу (пот, остатки шампуня и мыла).</div>
    <div class="rules">8.	Гостям запрещается открывать двери в служебные и технические помещения, щиты электрооборудования, шкафы пожарной безопасности.</div>
    <div class="rules">9.	Просим Гостей не использовать банные аксессуары (простыни, полотенца, тапочки) не по назначению.</div>
    <div class="rules">10. Решение о возможности и необходимости пребывания несовершеннолетних детей в банном комплексе и парилке принимается их родителями, опекунами, попечителями самостоятельно.</div>
    <div class="rules">11. Длительность пребывания в парных и саунах выбирайте в соответствии с состоянием здоровья и возможностями вашего организма.</div>
    <div class="rules">12. Запрещается посещение комплекса бань при наличии, либо подозрении на кожные, инфекционные и вирусные заболевания.</div>
  </div>
  <div class="rules-position">
    <legend class="rules-zag">О правилах пользования парной</legend>
    <div class="rules">1.	Перед посещением парной рекомендуем пройти консультацию у врачей на наличие противопоказаний.</div>
    <div class="rules">2.	Перед посещением парной рекомендуется принять теплый душ, при этом не мочить голову, затем обтереться.</div>
    <div class="rules">3.	Перед посещением парной снимите макияж, контактные линзы, украшения и часы.</div>
    <div class="rules">4.	Контролируйте длительность пребывания в парной в соответствии с состоянием здоровья и возможностями Вашего организма.</div>
    <div class="rules">5.	При входе в парную рекомендуем прикрыть голову х/б шапкой во избежание перегрева сосудов головы.</div>
    <div class="rules">6.	Будьте осторожны с горячей печью-каменкой.</div>
    <div class="rules">7.	После прогревания рекомендуем постепенное охлаждение: принять контрастный душ, окунутся в бассейне, купели.</div>
    <div class="rules">8.	Резкие перепады температуры небезопасны для здоровья. Не принимайте слишком горячий или холодной душ до того, как восстановите нормальную температуру тела и кровяное давление.</div>
    <div class="rules">9.	Запрещается посещать парную: </div>
    <div class="rules">- в течение 30 минут после интенсивных физических нагрузок; </div>
    <div class="rules">- менее чем через 1,5-2 часа после обильного приёма пищи; </div>
    <div class="rules">- натощак; </div>
    <div class="rules">- в состоянии алкогольного, наркотического и токсикологического опьянения; </div>
    <div class="rules">- при гнойничковых и контагиозных заболеваниях; </div>
    <div class="rules">- при любых недомоганиях.</div>
    <div class="rules">10.	Каждый посетитель бани несет личную ответственность за состояние своего здоровья во время посещения парной.</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'rules-page',
  components: {
  }
}
</script>

<style>
.v-rules-page{
  padding-left: 20px;
  padding-right: 20px;
  border: 5px solid #121820;
}
.rules-position{
    margin-right: 100px;
    width: 100%;
}
.rules-zag{
  font-size: 30px;
  margin-top: 10px;
}
.rules{
  text-align: justify;
  text-indent: 1.5em;
  margin-left: 20px;
  margin-top:10px;
  margin-bottom: 10px;
}
</style>
