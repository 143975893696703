import { createApp } from 'vue'
import App from './App.vue'
import Main from '@/pages/main-page'
import FamilyNumber from '@/pages/family-number'
import DiscontPage from '@/pages/disconts-page'
import Contacts from '@/pages/contacts-page'
import Price from '@/pages/price-page'
import Rules from '@/pages/rules-page'
import Common from '@/pages/common-page'
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

library.add(faChevronLeft, faChevronRight);
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter ({
    scrollBehavior (to){
        if (to.hash) {
            return {
              el: to.hash,
              behavior: 'smooth',
            }
          }
    },
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Main,
            name: 'Main',
        },
        {
            path: '/family-number',
            component: FamilyNumber,
            name: 'Family'
        },
        {
            path: '/disconts-page',
            component: DiscontPage,
            name: 'Discont'
        },
        {
            path: '/contacts-page',
            component: Contacts,
            name: 'Contacts'
        },
        {
            path: '/price-page',
            component: Price,
            name: 'Price'
        },
        {
            path: '/rules-page',
            component: Rules,
            name: 'Rules'
        },
        {
            path: '/common-page',
            component: Common,
            name: 'Common'
        }
    ],
    history: createWebHistory()
})

const app = createApp(App)

app
    .use(router)
    .mount('#app')
