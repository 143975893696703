<template>
<div class="v-main-page" id="main-page">
  <Gallery />
  <div class="text-container">
      <img src="@/images/banya/banyaLogo.png" id="Erchim" width="100px"/>
      <div class="zagolovok" id="zagolovok">Банный комплекс «Эрчим»</div>
  </div>
  <div class="help-background">
      <div class="help" style="padding-top: 20px;">БК «Эрчим» впервые принял своих гостей в 1985 году. Сейчас он представляет собой современную баню, включающую в себя общественное мужское и женское отделение, а так же 7 отдельных семейных номеров. Общая площадь банного комплекса составляет 2800 м2.
С уверенностью можно сказать, что «Эрчим», а в переводе с якутского языка это значит-«Сила», «Энергия», является одним из лучших банных комплексов на дальнем востоке.
Для удобства любителей русской бани мы выбрали круглосуточный режим работы отдельных номеров. Интерьер создан в современном стиле, удобная мебель и аромат банного веника — это то, что позволит вам расслабиться после тяжелого трудового дня, замечательно провести время в кругу семьи, друзей или коллег.
</div>
  </div>
  <CardGallery />
</div>
</template>

<script>
import Gallery from '@/components/v-gallery.vue'
import CardGallery from '@/components/v-card-gallery'

export default {
  name: 'main-page',
  components: {
    Gallery,
    CardGallery,
  }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.UDS{
  width: 100%;
  margin-top: 20px;
}
.UDS-image{
  display: inline-block;
  width: 50%;
}
.cards{
  width: 100%;
}
.MaleTime{
  width:500px;
  height: 230px;
  background-color:#f5f8fc;
  border-radius: 20px;
  border: 1px solid #121820
}
.male-time-card{
  display: inline-block;
  width: 49%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.time-card-header{
  margin-top: 30px;
  text-align: center;
      font-size: 40px;
  margin-bottom:20px;
      margin-left: 40px;
  left: 20%;
}
.v-main-page{
    border: 5px solid #121820;
}
.text-container {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.zagolovok{
    margin-top: 0;
    margin-left: 40px;
    font-size: 40px;
    display:inline-block; 
    text-align: center;
}
.cursiv-text{
    width: 25%;
    font-size: 15px;
    font-style: italic;
    display: inline-block;
    margin-left: 40px;
}

.help-background{
    background-color: #f5f8fc;
    padding-bottom: 20px;
    margin-bottom: 30px;
    margin-top: 60px;
}

.help{
    text-indent: 1.5em;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
    font-style: italic;
    font-size: 20px;
    width: 50%;
}

.spisok{
    margin-top: 60px;
    font-family: Helvetica;
    text-align: center;
    margin: 0 auto;
}
@media screen and (max-width: 700px) {
  .male-time-card{
    display: block;
    width: 100%;
  }
  .UDS-image{
    width: 100%;
    display: block;
  }
  .time-card-header{
    margin-left: 0px;
  }
    .zagolovok{
        margin-left: 2px;
        font-size: 30px;
    }
    .cursiv-text{
        display: none;
    }
    .help{
        width: 90%;
    }
    .MaleTime{
      width:300px;
      height: 230px;
      background-color:#f5f8fc;
      border-radius: 20px;
      border: 1px solid #121820
    }
}
</style>
