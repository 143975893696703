<template>
<div class="v-contacts" id="contacts">
    <iframe class="map" src="https://yandex.ru/map-widget/v1/?um=constructor%3A6718789313a6831744902f65af5658c3a6b89743a183e825d6a8b17356a9f949&amp;source=constructor" frameborder="0"></iframe>
    <div style="margin-left: 20px;"> 
      <p class="contact-number"><b>Контактный телефон:</b></p>
      <p class="contact-number">8 (914) 242-62-42</p>
      <p class="contact-number">4-99-70</p>
      <p class="contact-number"><b>Электронная почта:</b></p>
      <p class="contact-number">Erchim.rf@yandex.ru</p>
      <p class="contact-number"><b>Социальные сети:</b></p>
      <span><a href="https://t.me/ErchimBerloga"><img class="telegram" src="@/images/telegram_logo.png" /></a></span>
      <span><a href="https://vk.com/erchimbanya"><img class="telegram" src="@/images/VK_logo.png" /></a></span>
    </div>
</div>
</template>

<script>

export default {
  name: 'contacts-page',
  components: {
  }
}
</script>

<style>
.v-contacts{
  border: 5px solid #121820;
}
.telegram{
  width: 40px;
  margin-right: 5px;
}
.contacts{
  width: 50%;
  position: absolute;
  top: 10%;
  transform: translate(0, -60%);
}
.map{
  display: block;
  width: 90%;
  height: 400px;
  margin: 10px;
}
@media screen and (max-width: 700px) {
  .map {
    width: 100%;
    margin: 0px;
  }
  .telegram{
    width: 60px;
    text-align: center;
  }
  .contact-number{
    font-size: 20px;
  }
}
</style>
